import en_US from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import { format } from 'date-fns';

import { leadZeros } from "@/util/basic";

const LOCALE_CODE_TO_LOCALE = {
  en: en_US,
  pt: pt,
}

export function getDateFnsLocaleObject(localeCode) {
  return LOCALE_CODE_TO_LOCALE[localeCode] || en_US;
}

export const roundTimezoneOffsetFromDate = (date) => {
  const offsetRaw = format(date, "XXX")
  const split = offsetRaw.split(':');
  const hours = +split[0];
  const minutes = +split[1];
  let res = hours;
  if (minutes !== 0 && hours >= 0) {
    res++;
  }
  return leadZeros(res, true)

}
